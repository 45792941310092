import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import FIVE from "../../../../src/collections/blog/2024/2024-03-10-demystifying-open-source/meshery-skateboard.png";
import MESHERY from "../../../../src/collections/blog/2024/2024-03-10-demystifying-open-source/meshery-issues.png";
import STAR from "../../../../src/collections/blog/2024/2024-03-10-demystifying-open-source/star-meshery.png";
import STACK from "../../../../src/collections/blog/2024/2024-03-10-demystifying-open-source/tech-stack.png";
import GITHUB from "../../../../src/collections/blog/2024/2024-03-10-demystifying-open-source/github-issues.png";
import SEARCH from "../../../../src/collections/blog/2024/2024-03-10-demystifying-open-source/search-layer5.png";
import * as React from 'react';
export default {
  BlogWrapper,
  FIVE,
  MESHERY,
  STAR,
  STACK,
  GITHUB,
  SEARCH,
  React
};