import React from "react";

function MeshMapIcon() {
  return (
    <svg
      width="24px"
      height="24px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 218.18 350.43"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon
            fill="#00B39F"
            points="112.35 307.48 112.35 320.9 157.54 314.17 112.35 307.48"
          />
          <polygon
            fill="#00B39F"
            points="112.35 323.71 112.35 337.19 157.95 330.48 112.35 323.71"
          />
          <polygon
            fill="#00B39F"
            points="105.85 320.81 105.85 307.59 61.22 314.18 105.85 320.81"
          />
          <path
            fill="#00B39F"
            d="M16.33,337.14c8.75,3.62,20.61,6.7,34.62,9V332Z"
          />
          <polygon
            fill="#00B39F"
            points="105.85 337.13 105.85 323.8 61.11 330.47 105.85 337.13"
          />
          <polygon
            fill="#00B39F"
            points="57.45 345.27 102.76 338.6 57.45 331.86 57.45 345.27"
          />
          <polygon
            fill="#00B39F"
            points="161.05 345.34 161.05 331.95 115.81 338.61 161.05 345.34"
          />
          <path
            fill="#00B39F"
            d="M205.37,335.59c7.53-3.61,12.08-7.7,12.8-12L171,330.49Z"
          />
          <polygon
            fill="#00B39F"
            points="167.56 329.07 213.02 322.38 167.56 315.65 167.56 329.07"
          />
          <polygon
            fill="#00B39F"
            points="161.05 312.75 161.05 299.45 116.02 306.09 161.05 312.75"
          />
          <polygon
            fill="#00B39F"
            points="161.05 329.01 161.05 315.58 115.92 322.3 161.05 329.01"
          />
          <polygon
            fill="#00B39F"
            points="109.1 350.43 109.11 350.43 109.09 350.43 109.1 350.43"
          />
          <polygon
            fill="#00B39F"
            points="57.45 315.55 57.45 329.08 102.92 322.31 57.45 315.55"
          />
          <path
            fill="#00B39F"
            d="M105.85,294.24a377.87,377.87,0,0,0-47.92,3.26l47.92,7.09Z"
          />
          <polygon
            fill="#00B39F"
            points="57.45 299.36 57.45 312.8 102.96 306.09 57.45 299.36"
          />
          <path
            fill="#00B39F"
            d="M51,298.54c-14,2.28-25.88,5.35-34.63,9L51,312.65Z"
          />
          <path
            fill="#00B39F"
            d="M160.75,297.56a378.48,378.48,0,0,0-48.4-3.32V304.7Z"
          />
          <path
            fill="#00B39F"
            d="M0,323.31c.6,4.47,5.25,8.68,13.05,12.39l35.07-5.23Z"
          />
          <path
            fill="#00B39F"
            d="M13.05,309C5.28,312.65.64,316.83,0,321.28l48.16-7.11Z"
          />
          <path
            fill="#00B39F"
            d="M57.84,347.14a379,379,0,0,0,48,3.27V340.08Z"
          />
          <path
            fill="#00B39F"
            d="M202,307.55c-8.7-3.62-20.49-6.68-34.41-9v14.08Z"
          />
          <path
            fill="#00B39F"
            d="M167.56,346.06c14-2.29,25.85-5.38,34.56-9l-34.56-5.13Z"
          />
          <path
            fill="#00B39F"
            d="M218.18,321.21c-.67-4.41-5.28-8.56-13-12.22l-34.67,5.17Z"
          />
          <path
            fill="#00B39F"
            d="M112.35,350.41a379.37,379.37,0,0,0,47.9-3.25L112.35,340Z"
          />
          <polygon
            fill="#00B39F"
            points="50.95 328.96 50.95 315.69 6.19 322.3 50.95 328.96"
          />
          <path
            fill="#00B39F"
            d="M108.62,269.18c-9-16.35-17.86-31.18-25.4-46.67-15.8-32.48-31.41-65.08-46-98.1-7.27-16.4-12-33.72-10-52.35,3.38-31.85,28.45-61.35,59.7-69.2,44.38-11.15,86.08,11,101,54.24,6.4,18.58,3.81,37.16-2.55,55.48-12.84,37-30.57,71.83-48.56,106.5-7.65,14.74-16,29.14-24,43.68C111.73,264.49,110.57,266.11,108.62,269.18Zm.78-127.57c32.62-.28,59.7-27.62,59.38-60-.32-32.77-27.65-59.35-60.94-59.25-30.91.09-59.06,28.24-58.74,58.74C49.45,114.94,76.3,141.89,109.4,141.61Z"
          />
        </g>
      </g>
    </svg>
  );
}

export default MeshMapIcon;
