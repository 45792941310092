import { ResourcesWrapper } from "../../../../src/collections/resources/Resources.style.js";
import picture1 from "../../../../src/collections/resources/articles/kubernetes/getting-started-with-kubernetes/Picture1.webp";
import ClusterImg from "../../../../src/collections/resources/articles/kubernetes/getting-started-with-kubernetes/multi-cluster-kubernetes-management-with-meshery.webp";
import MeshmapDesigner from "../../../../src/collections/resources/articles/kubernetes/getting-started-with-kubernetes/MeshmapDesigner.webp";
import MeshmapVisualizer from "../../../../src/collections/resources/articles/kubernetes/getting-started-with-kubernetes/MeshmapVisualizer.webp";
import * as React from 'react';
export default {
  ResourcesWrapper,
  picture1,
  ClusterImg,
  MeshmapDesigner,
  MeshmapVisualizer,
  React
};